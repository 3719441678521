/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:07:53
 * @Module: 在线充值
 */
 <template>
  <div>
    <div class="TopUp" style="background:#fff;">
      <div class="explain" v-show="coinType=='COIN'">
        <h3>充值说明</h3>
        <p>1、请使用支付宝进行充值；<br />
          2、账户充值后，即可使用各项功能，使用功能所发生的费用将在余额中扣除， 费用情况请参照<el-link type="primary">“ 功能服务资费标准” </el-link>；<br />
          3、账户余额不可转让、不提现、不找零。</p>
      </div>
      <div class="explain1" v-show="coinType=='BEAN'">
        <h3>充值说明</h3>
        <p>1、请使用支付宝进行充值；<br />

          2、账户充值后，即可使用查小号功能， 费用情况请参照<el-link type="primary" @click="beanRulesShow">“ 功能服务资费标准” </el-link>；<br />
          3、账户余额不可转让、不提现、不找零。</p>
      </div>
    </div>

    <div style="margin-top:10px;">
      <div>
        <el-tabs v-model="coinType" @tab-click="onswitch" type="card">
          <el-tab-pane label="金币充值" name="COIN"></el-tab-pane>
          <el-tab-pane label="卡券充值" name="BEAN"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 金币充值 -->
      <div style="overflow:hidden;padding:20px 0;" v-show="coinType=='COIN'" class="COIN">
        <!-- 套餐选择 -->
        <div class="packages">
          <ul class="packages-list">
            <li class="packages-list-item" :class="{'active':item.packageId==packageId}" @click="()=>packagesClick(item)" v-for="item in list" :key="item.packageId">
              <p class="packages-list-item-t">
                <i class="gold-icon coins-icon"></i>
                {{item.coins}}金币
              </p>
              <p class="packages-list-item-c">
                <span class="l">{{item.price}}元</span>
                <span class="r">{{item.coins}}元</span>
              </p>
              <div class="discount">{{(item.price/item.coins)*10}}折</div>
            </li>
            <li class="packages-list-item" style="padding: 0 30px;" @click="()=>{packageId=0;coins=0;}" :class="{'active':packageId==0}">
              <p>自定义充值</p>
              <el-input style="margin-top:10px;" v-show="packageId==0" v-model="coins" placeholder="自定义金额" oninput="value=value.replace(/[^\d]/g,'')" size="mini"> <template slot="append">元</template></el-input>
              <b v-show="packageId==0">（充值1元=1金币）</b>
            </li>
          </ul>
        </div>
        <div style="padding:0 24px">
          <el-divider></el-divider>
        </div>

        <div class="content">
          <div class="item">
            <p class="lable">充值账户：</p>
            <span class="value">{{company}}</span>
          </div>

          <div class="item">
            <p class="lable">金币余额：</p>
            <span class="value">{{balance}}金币</span>
          </div>
          <div class="item">
            <p class="lable">支付方式：</p>
            <span class="value">
              <span class="el-icon-ali-zhifubaozhifu"></span>
              <span>支付宝</span>
            </span>
          </div>
          <div class="item">
            <p class="lable">实付金额：</p>
            <span class="value coins">{{coins}}</span>
          </div>
        </div>
        <div style="padding:0 24px;">
          <el-button style="width:110px;" type="primary" size="mini" @click="postRecharge">充值</el-button>
        </div>
      </div>

      <!-- 卡券充值 -->
      <div style="overflow:hidden;padding:20px 0;" v-show="coinType=='BEAN'" class="BEAN">
        <!-- 套餐选择 -->
        <div class="packages">
          <ul class="packages-list">
            <li class="packages-list-item" :class="{'active':item.packageId==packageId}" @click="()=>packagesClick(item)" v-for="item in BEANlist" :key="item.packageId">
              <p class="packages-list-item-t">
                <i class="gold-icon"></i>
                {{item.coins}}卡券
              </p>
              <p class="packages-list-item-c">
                <span class="l">{{item.price}}元</span>
                <span class="r">{{item.coins}}元</span>
              </p>
              <div class="discount">{{(item.price/item.coins)*10}}折</div>
            </li>
            <li class="packages-list-item" style="padding: 0 30px;" @click="()=>{packageId=0;coins=0;}" :class="{'active':packageId==0}">
              <p>自定义充值</p>
              <el-input style="margin-top:10px;" v-show="packageId==0" v-model="coins" placeholder="自定义金额" oninput="value=value.replace(/[^\d]/g,'')" size="mini"> <template slot="append">元</template></el-input>
              <b v-show="packageId==0">（充值1元=1卡券）</b>
            </li>
          </ul>
        </div>
        <div style="padding:0 24px">
          <el-divider></el-divider>
        </div>

        <div class="content">
          <div class="item">
            <p class="lable">充值账户：</p>
            <span class="value">{{company}}</span>
          </div>

          <div class="item">
            <p class="lable">卡券余额：</p>
            <span class="value">{{beans||0}}卡券</span>
          </div>
          <div class="item">
            <p class="lable">支付方式：</p>
            <span class="value">
              <span class="el-icon-ali-zhifubaozhifu"></span>
              <span>支付宝</span>
            </span>
          </div>
          <div class="item">
            <p class="lable">实付金额：</p>
            <span class="value coins">{{coins}}</span>
          </div>
        </div>
        <div style="padding:0 24px;">
          <el-button style="width:110px;" type="primary" size="mini" @click="postRecharge">充值</el-button>
        </div>
      </div>

      <div v-html="h" />
      <beanRules ref="beanRules" />
    </div>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import beanRules from "@/views/ExpenseCenter/components/beanRules.vue";
export default {
  components: {
    beanRules
  },
  data () {
    return {
      list: [],
      BEANlist: [],
      packageId: 0,
      coins: 0,
      radio: '1',
      h: '',
      coinType: 'COIN',
    };
  },
  computed: {
    ...mapState({
      balance: state => state.user.balance,
      company: state => state.user.user.company,
      beans: state => state.user.beans,
    })
  },
  watch: {
    "$route.params": {
      handler ({ type }) {
        type && (this.coinType = type)
      },
      immediate: true
    }
  },
  mounted () {
    this.$store.dispatch('user/getCompanyBalance');
    this.postRechargePackage()
  },
  methods: {
    async postRecharge () {
      try {
        let parm = {};
        parm.coinType = this.coinType
        if (this.packageId) {
          parm.packageId = this.packageId
        } else {
          parm.coins = this.coins;
          if (this.coinType === 'BEAN' && this.coins < 100) {
            this.$message({
              type: 'error',
              message: '卡券最低充值100个'
            })
            throw '卡券最低充值100个'
          }
        }

        const { status, info, data } = await this.$api.postRecharge(parm)
        if (status === 200) {
          this.h = info
          this.$nextTick(() => {
            document.forms[0].submit()
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    async postRechargePackage () {
      const { data, status } = await this.$api.postRechargePackage()
      if (status === 200) {
        this.list = data;
      }
      const { data: BEANlist, status: BEANstatus } = await this.$api.postRechargePackage({ coinType: 'BEAN' })
      if (BEANstatus === 200) {
        this.BEANlist = BEANlist;
      }
    },
    packagesClick (item) {
      this.packageId = item.packageId;
      this.coins = item.price;
    },
    onswitch () {
      // this.coinType = this.coinType == 'COIN' ? 'BEAN' : 'COIN'
      this.packageId = 0;
      this.coins = 0
    },
    beanRulesShow () {
      this.$refs.beanRules.show()
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
// /deep/ #tab-COIN.is-active {
//   background: #fafdff;
//   border-bottom-color: #fafdff;
// }

// /deep/ #tab-BEAN.is-active {
//   background: #fff;
//   border-bottom-color: #fff;
// }
.COIN {
  // background: #f66;
  // background-image: url(~@/static/image/cion_bg.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  // background-color: #fafdff;
  background: #fff;
  .packages {
    padding: 0 24px;
    .active {
      border: 2px solid $--color-primary;
      .discount {
        background: $--color-primary;
        color: #fff;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      &-item {
        // width: 100%;
        width: calc((100% - (24px * 4)) / 5);
        // width: 200px;
        height: 120px;
        border: 2px solid #e3e3e3;
        margin-right: 24px;
        &:nth-child(5n) {
          margin-right: 0px;
        }
        // margin-right: 3.15%;
        cursor: pointer;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;
        .discount {
          font-size: 15px;
          position: absolute;
          background: rgba($color: $--color-primary, $alpha: 0.1);
          color: $--color-primary;
          width: 100%;
          text-align: center;
          transform: rotate(-45deg);
          left: calc(-50% + 20px);
          top: 15px;
        }
        &-t {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 37px;
        }
        &-c {
          margin-top: 2px;
          .l {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #0062ff;
            line-height: 25px;
          }
          .r {
            text-decoration: line-through;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-left: 7px;
          }
        }
      }
    }
    .button {
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }
  }
}
.BEAN {
  // background-image: url(~@/static/image/bean_bg1.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  // background-color: #fff;
  background: #fff;
  .packages {
    padding: 0 24px;
    .active {
      border: 2px solid #f5e200;
      .discount {
        background: #f5e200;
        color: #fff;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      &-item {
        // width: 100%;
        width: calc((100% - (24px * 4)) / 5);
        // width: 200px;
        height: 120px;
        border: 2px solid #e3e3e3;
        margin-right: 24px;
        &:nth-child(5n) {
          margin-right: 0px;
        }
        // margin-right: 3.15%;
        cursor: pointer;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;
        .discount {
          font-size: 15px;
          position: absolute;
          background: rgba(#f5e200, $alpha: 0.1);
          color: #f5e200;
          width: 100%;
          text-align: center;
          transform: rotate(-45deg);
          left: calc(-50% + 20px);
          top: 15px;
        }
        &-t {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 37px;
        }
        &-c {
          margin-top: 2px;
          .l {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #f5e200;
            line-height: 25px;
          }
          .r {
            text-decoration: line-through;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-left: 7px;
          }
        }
      }
    }
    .button {
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }
  }
}
.explain {
  padding: 12px 24px;
  // background: #fafdff;
  // background-image: url(~@/static/image/cion_bg.png);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 26px;
  // margin: 12px 24px;
}
.explain1 {
  padding: 12px 24px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 26px;
}
.content {
  padding: 0 24px;
  .item {
    display: flex;
    height: 50px;
    align-items: center;
    .lable {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-right: 10px;
    }
    .value {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
      .el-icon-ali-zhifubaozhifu {
        color: #0062ff;
        margin-right: 6px;
      }
    }
    .coins {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e05353;
      line-height: 25px;
    }
  }
}
.button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  &/deep/.el-button {
    width: 500px;
  }
}

b {
  font-size: 12px;
  color: #d43030;
  margin-top: 10px;
  font-weight: 400;
}
.gold-icon {
  display: table-cell;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(~@/static/image/gold.png);
  margin-right: 5px;
  background-size: 20px;
}
.coins-icon {
  background-image: url(~@/static/image/coins.png);
}
</style>